import React from 'react'
import './Register.scss'
import logo from '../../assets/images/logo.png'
import person from '../../assets/images/person.png'
import unit from '../../assets/images/unit.png'

class Register extends React.Component {
  state = {

  };
  render() {
    return (
      <div className="register">
        <div className="loginLog">
          <img className="logoImage" src={logo} alt="logo" />
        </div>
        <div className="registerContent">
          <div className="registerBox">
            <div className="registerHeader"><span>长江技术经济学会简介</span></div>
            <div className="registerContentOne">
              <span style={{ marginLeft: "20px" }}>长江技术经济学会欢迎您！<br /></span>
              <span style={{ marginLeft: "20px" }}>长江技术经济学会（Changjiang Technology and Economy Society，CTES）是由科技部主管、并在民政部依法登记的跨地区、跨部门、跨学科的全国性学术团体，是科技部下属17个全国性学术团体（基金会）之一，委托管理单位为水利部长江水利委员会，学会秘书处支撑单位为长江水利委员会长江科学院，办公住所位于湖北省武汉市。<br /></span>
              {/* <span className="contenrOneTitle">凡申请加入本会的会员，须具备下列条件：<br /></span> */}
              {/* <span style={{marginLeft: "20px"}}>（一）拥护本会的章程<span style={{marginLeft: "100px"}}>（二）自愿加入并积极支持和参与协会活动</span><br /><span style={{marginLeft: "20px"}}>（三）在中国境内的水利工程建设管理、施工、监理、运行管理、维修养护的企事业单位及其他相关组织和个人。</span><br /></span>
              <span className="contenrOneTitle">会员享有下列权利：<br /></span>
              <span style={{marginLeft: "20px"}}>（一）本会的选举权、被选举权和表决权</span><span style={{marginLeft: "100px"}}>（二）参加本会的活动</span><span style={{marginLeft: "100px"}}>（三）获得本会服务的优先权</span><br /><span style={{marginLeft: "20px"}}>（四）对本会工作的批评建议权和监督权</span><span style={{marginLeft: "100px"}}>（五）入会自愿、退会自由。</span><br />
              <span className="contenrOneTitle">会员应履行下列义务：</span><br />
              <span style={{marginLeft: "20px"}}>（一）执行本会的决议</span>
              <span style={{marginLeft: "100px"}}>（二）维护本会合法权益</span>
              <span style={{marginLeft: "100px"}}>（三）完成本会交办的工作</span><br />
              <span style={{marginLeft: "20px"}}>（四）按规定交纳会费</span>
              <span style={{marginLeft: "100px"}}>（五）向本会反映情况，提供有关资料</span><br />
              <span style={{marginLeft: "20px"}}>（六）宣传本会的宗旨和作用，关心本会的建设与发展。我们热忱欢迎您加入中国水利工程协会，我们将热情为您提供相关服务。</span><br /> */}
              {/* <span style={{marginLeft: "20px"}}>单位入会申请提交后，请自行登录“单位会员中心”，上传<span style={{color: "red"}}>加盖公章的单位入会申请表和社会统一信用代码证</span>。</span><br />
              <span style={{marginLeft: "20px"}}>请申请加入我协会的单位，点击下方的“<span style={{color: "red"}}>单位申请入会</span>”进入。具体操作请见“<span className="flowImage">单位申请入会流程图</span>（下载）”。</span><br />
              <span style={{marginLeft: "20px"}}>请申请加入我协会的个人，点击下方的“<span style={{color: "red"}}>个人申请入会</span>”进入。具体操作请见“<span className="flowImage">个人申请入会流程图</span>（下载）”。</span><br /> */}
              <span style={{ marginLeft: "20px" }}>1993年，学会经全国政协原副主席、曾任中共中央中南局书记、国务院副总理王任重同志提议，由湖北省人民政府、长江水利委员会等六家单位发起成立，时任全国政协副主席钱正英同志担任学会名誉理事长。学会自成立以来，得到了国家及有关部委和长江流域有关省市各级领导的高度重视和大力支持。近30年来始终秉承为长江流域经济社会发展服务的宗旨，着眼于长江流域的自然资源、区域经济、水利能源、交通航运、生态环境等技术经济问题，通过理论研究、学术交流、技术咨询等方式，积极为中央和地方政府建言献策，形成了许多有意义、有创建、有价值的重大学术成果，并创办《长江技术经济》期刊，有力地推动了长江流域经济社会的发展，对促进长江经济带建设作出了重要贡献。<br /></span>
              <span style={{ marginLeft: "20px" }}>长江技术经济学会期待着您的加入！<br /><br /></span>
              <span style={{ marginLeft: "20px" }}>会费标准如下：<br /></span>
              <span style={{ marginLeft: "20px" }}>（一）单位会员会费：1000元/年；<br /></span>
              <span style={{ marginLeft: "20px" }}>（二）个人会员会费：不收取会费。<br /></span>
            </div>
            <div className="registerContentTwo">
              <div className="contact">
                <span className="contentTwoTitle">联系人</span><br />
                {/* <span style={{ marginLeft: "20px" }}>杨恩：027-82926221/15827296951</span><br /> */}
                <span style={{ marginLeft: "20px" }}>王黎明：027-82926221/18994903640</span><br />
                <span style={{ marginLeft: "20px" }}>传真：027-82926221</span><br />
                <span style={{ marginLeft: "20px" }}>邮箱：office@cjxh.org.cn</span><br />
                <span style={{ marginLeft: "20px" }}>地址：湖北省武汉市黄浦大街23号</span><br />
                <span style={{ marginLeft: "20px" }}>邮编：430010</span>
              </div>
              {/* <div className="account">
                <span className="contentTwoTitle">汇款单位及账号</span><br />
                <span style={{marginLeft: "20px"}}>单位全称: 长江技术经济学会</span><br />
                <span style={{marginLeft: "20px"}}>账号: 3202002209000061195</span><br />
                <span style={{marginLeft: "20px"}}>开 户 行: 中国工商银行武汉长委支行 </span><br />
                <span style={{marginLeft: "20px"}}>（汇款请注明“2020单位会员+单位名称”）</span>
              </div> */}
            </div>
            <div className="registerContentThree">
              <div className="registerThree">
                <div className="registerImage">
                  {/* <i className="iconfont icon-yonghu unit"></i> */}
                  <img style={{ width: '30px', height: '30px' }} src={unit} alt="单位" />
                </div>
                <div className="registerTitle" onClick={() => this.props.history.push('/registerunit')}>
                  <span style={{ fontSize: "16px", fontWeight: 600 }}>单位会员</span><br />
                  <span>申请入会</span>
                </div>
              </div>
              {/* <div className="registerThree">
                <div className="registerImage">
                  <i className="iconfont icon-yonghu1 person"></i>
                </div>
                <div className="registerTitle" onClick={() => this.props.history.push('/registerexpert')}>
                  <span style={{fontSize: "16px", fontWeight: 600}}>专家</span><br />
                  <span>申请入会</span>
                </div>
              </div> */}
              <div className="registerThree">
                <div className="registerImage">
                  {/* <i className="iconfont icon-yonghu1 person"></i> */}
                  <img style={{ width: '30px', height: '30px' }} src={person} alt="个人" />
                </div>
                <div className="registerTitle" onClick={() => this.props.history.push('/registerperson')}>
                  <span style={{ fontSize: "16px", fontWeight: 600 }}>个人会员</span><br />
                  <span>申请入会</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">©2021 长江技术经济学会 版权所有</span>
        </div>
      </div>
    )
  }
}

export default Register
